import '../../App.css'
import ContactPage from '../ContactPage'
import React from 'react'

function Contact() {
    return (
        <>
            <ContactPage />
        </>
    )
}


export default Contact;