import '../../App.css'
import MasterBathroomPage from '../MasterBathroomPage'
import React from 'react'

function MasterBathroom() {
    return (
        <>
            <MasterBathroomPage />
        </>
    )
}


export default MasterBathroom;