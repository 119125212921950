import '../../App.css'
import ExteriorPage from '../ExteriorPage'
import React from 'react'

function Exterior() {
    return (
        <>
            <ExteriorPage />
        </>
    )
}


export default Exterior;