import '../../App.css'
import GalleryPage2 from '../GalleryPage2'
import React from 'react'

function Gallery() {
    return (
        <>
            <GalleryPage2 />
        </>
    )
}


export default Gallery;