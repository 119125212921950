import '../../App.css'
import WoodworkPage from '../WoodworkPage'
import React from 'react'

function Woodwork() {
    return (
        <>
            <WoodworkPage />
        </>
    )
}


export default Woodwork;