import '../../App.css'
import CabanaPage from '../CabanaPage'
import React from 'react'

function Cabana() {
    return (
        <>
            <CabanaPage />
        </>
    )
}


export default Cabana;