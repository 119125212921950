import '../../App.css'
import AboutPage from '../AboutPage'
import React from 'react'

function About() {
    return (
        <>
            <AboutPage />
        </>
    )
}


export default About;