import '../../App.css'
import HomePage from '../HomePage'
import React from 'react'

function Home() {
    return (
        <>
            <HomePage />
        </>
    )
}


export default Home;