import '../../App.css'
import PoolHousePage from '../PoolHousePage'
import React from 'react'

function PoolHouse() {
    return (
        <>
            <PoolHousePage />
        </>
    )
}


export default PoolHouse;